a {
  color: $link-color;
  text-decoration: $link-decoration;

  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}
