// Baseguide setting overrides

// Global
$font-antialiased: false !default;
$font-size-base: 1rem !default;
$font-family-base: "Work Sans", sans-serif;
$font-weight-base: 400 !default;
$line-height-base: 1.5 !default;
$global-bg: #fff !default;
$text-color: $black;

// Headings
$headings: (
  "h1, .h1",
  "h2, .h2",
  "h3, .h3",
  "h4, .h4",
  "h5, .h5",
  "h6, .h6"
) !default;
$headings-spacing: 0.4em !default;
$headings-font-family: inherit !default;
$headings-font-weight: 700 !default;
$headings-line-height: 1.2 !default;
$headings-color: inherit !default;
$type-scale-base: $font-size-base !default;
$type-scale: 1.25 !default;

// Inputs
$input-height: 3em;
$input-padding-vertical: 0.375em !default;
$input-padding-horizontal: 0.75em !default;
$input-bg: #fff !default;
$input-color: $text-color !default;
$input-border-color: #dadaed;
$input-focus-border-color: darken($input-border-color, 20%) !default;
$input-border-radius: 4px;
$input-transition: border-color 0.15s ease-out !default;
$input-disabled-bg: #eee !default;
$input-disabled-color: #555 !default;
$input-disabled-border-color: #ccc !default;
$placeholder-color: lighten($input-color, 50%) !default;

// Button
$button-selector: ".Button";
$button-padding-vertical: 0.375em !default;
$button-padding-horizontal: 1em !default;
$button-bg: $primary;
$button-color: $white;
$button-border-width: 1px !default;
$button-border-color: transparent !default;
$button-border-radius: 4px;
$button-hover-bg: $light-primary;
$button-hover-color: #fff !default;
$button-hover-border-color: $button-border-color !default;
$button-active-bg: $button-hover-bg !default;
$button-active-color: $button-hover-color !default;
$button-disabled-opacity: 0.65 !default;
$button-transition: background-color 0.15s ease-out,
  color 0.15s ease-out !default;

// Table
$table-selector: "Table";
$table-padding-vertical: 0.4em !default;
$table-padding-horizontal: 0.6em !default;
$table-border-color: $medium-grey;
$table-stripe-bg: $white;
