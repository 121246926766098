#{$table-selector} {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  th,
  td {
    padding: $table-padding-vertical $table-padding-horizontal;
    border-bottom: 1px solid $table-border-color;
    vertical-align: top;
  }

  th {
    text-align: left;
  }

  thead th {
    border-bottom-width: 2px;
    vertical-align: bottom;
  }

  tbody tr:nth-child(2n + 1) {
    background-color: $table-stripe-bg;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
