.PrivacyPolicy {
  color: $secondary;
  padding: 2rem 0 4rem;
}

.PrivacyPolicy-title {
  font-size: 2rem;
  font-weight: normal;
  line-height: 2.125rem;

  @include mq(md) {
    font-size: 3rem;
    line-height: 3.125rem;
  }
}

.PrivacyPolicy-subtitle {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
}
