blockquote,
figure,
fieldset {
  margin: 0;
}

address,
blockquote,
table,
figure,
form,
fieldset,
legend,
pre,
dl,
ul,
ol,
hr,
p {
  margin-top: 0;
  margin-bottom: $spacing-base;

  @if $spacing-reset {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}
