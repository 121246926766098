$footer-height: 245px;

.Footer {
  background-color: $secondary;
  min-height: $footer-height;
  padding: 4rem 0 2rem;
  text-align: center;
}

.Footer-logo {
  width: 190px;
}

.Footer-copyright {
  color: $white;
  font-weight: 300;
  padding-top: 1rem;
}

.Footer-links {
  list-style-type: none;
  padding: 0;

  > li {
    display: inline-block;
  }
}

.Footer-partners {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 1rem;
}

.Footer-partner {
  height: 80px;
  margin: 0 0.75rem;
  width: 80px;

  @include mq(sm) {
    height: 100px;
    width: 100px;
  }

  @include mq(md) {
    height: 110px;
    margin: 0 1rem;
    width: 110px;
  }
}

.Footer-link {
  color: $white;
  font-weight: 300;
  padding: 0 1rem;
  transition: $button-transition;

  &:hover {
    color: darken($white, 10%);
    text-decoration: none;
  }
}
