.Button {
  background-color: $pink;
  background-color: linear-gradient(45deg, $bg-pink 0%, $pink 100%);
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  filter: progid:dximagetransform.microsoft.gradient( startcolorstr='$bg-pink', endcolorstr='$pink',gradienttype=1 );

  &--rounded {
    border-radius: 1.5em;
  }

  &--inverse {
    @extend .Button;

    background-color: transparent;
    border: 1px solid $button-bg;
    border-radius: 1.5em;
    color: $button-bg;

    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      border-color: $button-hover-bg;
      color: $button-hover-bg;
    }
  }

  &--inverseNoBorders {
    @extend .Button--inverse;

    background: none;
    border: 0;
    box-shadow: none;
  }

  &--wide {
    padding: $button-padding-vertical 6em;
    white-space: nowrap;
  }

  &--fullWidth {
    width: 100%;
  }

  &--link {
    background-color: transparent;
    border: 0;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }
}
