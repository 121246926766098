body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  background-color: $global-bg;
  color: $text-color;
}

@if $font-antialiased {
  html,
  button,
  input {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }
}
