h1,
h2,
h3 {
  color: $secondary;
  font-weight: 500;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.875rem;
  font-weight: 400;
}

h3 {
  font-size: 1.125rem;
}

.Heading--secondary {
  color: $secondary;
}
