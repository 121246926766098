$dark-pink: #ff5f83;
$bg-pink: #d36f86;
$pink: #ff96ac;
$light-pink: #ffbbc5;
$lighter-pink: #fdeaee;
$green: #3b5f5f;
$bright-green: #dbffe5;
$brown: #2c1c1e;
$white: #fff;
$white-bg: #f6f6f6;
$lighter-grey: #edf1f1;
$light-grey: #f1f1f1;
$medium-grey: #cfcfcf;
$medium-dark-grey: #999;
$dark-grey: #979797;
$darkish-grey: #9b9b9b;
$black: #000;
$primary: $pink;
$secondary: $green;
$main-text: #333;
$light-primary: lighten($primary, 9%);
$lighter-primary: lighten($primary, 15%);
$light-secondary: lighten($secondary, 9%);
