.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 0 1 auto;
}

.media-left,
.media-right {
  flex: 0 0 auto;
}

.media-left {
  padding-right: 1rem;
}

.media-right {
  order: 1;
  padding-left: 1rem;
}

.media-middle {
  align-self: center;
}

.media-bottom {
  align-self: flex-end;
}

.media-fullscreen {
  position: absolute;
  left: 50%;
  top: 50%;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  max-width: none;
  transform: translate(-50%, -50%);
}
