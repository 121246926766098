$header-height: 66px;

.Header {
  background-color: $lighter-grey;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  height: $header-height;
  position: relative;
  z-index: 1;

  &--white {
    background-color: $white;
    box-shadow: none;

    .Header-menuContainer {
      background-color: $white;
    }
  }
}

.Header-logo {
  height: 30px;
  margin: 16px 0 20px;
}

@media (width >= 60em) {
  .Header-logoCol {
    width: 13.5%;
  }

  .Header-menuCol {
    padding-left: 0;
    width: 86.5%;
  }
}

.Header-menuButton {
  background: transparent;
  border: none;
  cursor: pointer;
  float: right;
  margin-top: 16px;

  @include mq(lg) {
    display: none;
  }

  > img {
    height: 16px;
  }
}

.Header-menuContainer {
  background-color: $lighter-grey;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  float: right;
  margin: 0 -0.9375rem;
  position: relative;
  z-index: 5;

  @include mq(lg) {
    box-shadow: none;
    z-index: initial;
  }
}

.Header-menu {
  list-style-type: none;
  margin: 0;
  max-height: 0;
  overflow: hidden;
  padding: 0;

  @include mq(lg) {
    box-shadow: none;
    max-height: 100%;
  }

  &--open {
    max-height: 100%;
  }
}

.Header-menuItem {
  padding: 1rem 2rem;

  @include mq(lg) {
    display: inline;
    line-height: $header-height;
    padding: 0 1rem;
  }

  > a,
  &.Header-languageSelect {
    color: $secondary;
    font-weight: 500;
    transition: $button-transition;

    &:hover {
      color: $light-secondary;
      text-decoration: none;
    }
  }
}

.Header-profileImage {
  border-radius: 20px;
  bottom: 4px;
  margin-left: 0.5rem;
  max-height: 40px;
  max-width: 40px;
  position: relative;
}

.Header-login {
  line-height: initial;
  width: 177px;
}

.Header-language--mobile {
  @include mq(lg) {
    display: none;
  }
}

.Header-language--desktop {
  @include mq($until: lg) {
    display: none !important;
  }
}

.Header-languageSelect {
  cursor: pointer;

  &.Header-language--desktop {
    border-left: 2px solid #bebebe;
    line-height: 2rem;
    padding-left: 0.8rem;
  }
}

.Header-language--hidden {
  display: none !important;
}

.Header-languageDropdown {
  background-color: $lighter-grey;
  display: inline-block;
  position: absolute;
  right: 0;
  top: 3rem;
  width: 5rem;

  .Header--white & {
    background-color: initial;
  }

  > a.Header-languageItem {
    display: list-item;
    line-height: initial;
    padding: 0.5rem 0.5rem 0.5rem 0;
  }
}

.Header-languageSelect.Header-language--desktop {
  display: inline-block;
  width: 5rem;
}
