.LandingSectionContent {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &--padding {
    padding-bottom: 1rem;
    padding-top: 1rem;

    @include mq(sm) {
      padding-bottom: 2rem;
      padding-top: 2rem;
    }

    @include mq(md) {
      padding-bottom: 4rem;
      padding-top: 4rem;
    }

    @include mq(lg) {
      padding-bottom: 6rem;
      padding-top: 6rem;
    }
  }
}

.LandingSection-bp {
  padding-bottom: 2rem;
}

.LandingSection-tp {
  padding-top: 2rem;
}

.HeroSection {
  background-image: url("/images/hero-section-mobile.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: calc(100vh - #{$header-height});

  @include mq(sm) {
    background-image: url("/images/hero-section-tablet.svg");
  }

  @include mq(md) {
    background-image: url("/images/hero-section-desktop.svg");
  }
}

.HeroSectionContent {
  padding-top: 10%;

  @include mq(lg) {
    background-position: right bottom;
    background-repeat: no-repeat;
  }
}

.HeroSectionContent-title {
  color: $secondary;
  font-weight: 500;
}

.HeroSectionContent-button {
  margin: 2rem 0;
}

.HeroSectionContent-text {
  font-size: 0.875rem;
  margin: 0;

  @include mq(md) {
    font-size: 1rem;
  }
}

.LandingSection2-image {
  margin: 0 0 4rem;

  @include mq(lg) {
    height: 400px;
    margin-top: 6rem;
  }
}

.LandingSection3 {
  background-color: $light-pink;
}

.LandingSection3-image {
  max-width: 365px;
  padding: 6rem 0;
}

.LandingSection4-image {
  @include mq(lg) {
    max-width: initial;
    min-height: 594px;
    width: auto;
  }
}

.LandingSection5 {
  background-color: $lighter-grey;
}

.LandingSection6 {
  @include mq(xl) {
    background-image: url("/images/landing-section-6-image.png");
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.BlogLinkSection {
  padding-top: 2rem;

  @include mq(xl) {
    align-items: flex-start;
  }
}

.auth0-lock.auth0-lock .auth0-lock-name {
  display: none;
}

.auth0-lock.auth0-lock .auth0-lock-header {
  height: 80px !important;
}
