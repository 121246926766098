.block-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}
