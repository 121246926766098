.invisible {
  visibility: hidden !important;
}

.hidden {
  display: none !important;
}

@media print {
  .hidden-print {
    display: none !important;
  }
}

@include loop-breakpoints($mq: false) {
  .visible-#{$breakpoint} {
    display: none !important;
  }

  @if not($is-first-breakpoint or $is-last-breakpoint) {
    .visible-#{$breakpoint}-up {
      display: none !important;
    }
  }
}

@include loop-breakpoints($inclusive: false) {
  .hidden-#{$breakpoint} {
    display: none !important;
  }

  .visible-#{$breakpoint} {
    display: block !important;
  }
}

@include loop-breakpoints {
  @if not($is-first-breakpoint or $is-last-breakpoint) {
    .hidden-#{$breakpoint}-up {
      display: none !important;
    }

    .visible-#{$breakpoint}-up {
      display: block !important;
    }
  }
}
