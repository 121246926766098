.Blog {
  background: $white-bg;
}

.Posts-title {
  color: $pink;
  font-size: 3.75rem;
  font-weight: 500;
  margin: 2rem 0 1rem !important;
}

.Posts-subtitle {
  font-size: 1.25rem;
  margin-bottom: 4rem;
}

.Posts {
  column-count: 1;
  gap: 2rem 2rem;
  line-height: 0;
  margin-bottom: 4rem;

  @include mq(md) {
    column-count: 2;
  }

  @include mq(lg) {
    column-count: 3;
  }
}

.Post {
  background: $white;
  margin: 2rem 0;
  padding: 2rem 1rem;
}

.Post-title {
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 3rem;
}

.Post-info {
  font-size: 0.875rem;
  line-height: 1rem;
}

.Post-separator {
  margin: 0 0.5rem;
}

.Post-image {
  margin-bottom: 2rem;
}

.Post-button {
  border: 2px solid $pink;
  border-radius: 4px;
  font-weight: 500;
  margin: 2rem 0 4rem;

  &--next {
    float: right;
  }
}

.SimilarPosts-title {
  font-size: 2rem;
  font-weight: 500;
}

.SimilarPosts {
  column-count: 1;
  gap: 2rem 2rem;
  margin-bottom: 4rem;

  @include mq(md) {
    column-count: 2;

    > .PostCard {
      display: block;
      margin: 0;
    }
  }
}

.PostCard {
  background: $white;
  break-inside: avoid;
  color: $black;
  column-break-inside: avoid;
  display: block;
  margin-bottom: 2rem;
  page-break-inside: avoid;

  &:hover,
  &:active,
  &:focus {
    color: $black;
    text-decoration: none;
  }
}

.PostCard-content {
  padding: 1rem;
}

.PostCard-title {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  margin-bottom: 1rem;
}

.Tags {
  margin: 2rem 0;
}

.Tags-title {
  font-size: 2rem;
  font-weight: 500;
}

.Tags-tag {
  background: $light-pink;
  border: 1px solid $pink;
  border-radius: 2px;
  color: $black;
  display: inline-block;
  font-size: 0.875rem;
  margin: 0.25rem 0.25rem 0 0;
  padding: 0.25rem 0.5rem;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &:hover,
  &:active,
  &:focus {
    color: $black;
    text-decoration: none;
  }
}
