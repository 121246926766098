@use "sass:math";

.embed-responsive {
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    padding-bottom: percentage(math.div(9, 16));
  }

  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}
